import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/emilylouie/Documents/workspace2021/emilyslouie.github.io/src/components/projectLayout.js";
import { Link, graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import kpi from "../images/kpi.png";
import callToAction from "../images/sorenewals/calltoactions.png";
import redundancy from "../images/sorenewals/redundancy.png";
import oneWindow from "../images/sorenewals/onewindow.png";
import simpleQuestions from "../images/sorenewals/simplequestions.png";
import errors from "../images/sorenewals/errors.png";
import error1 from "../images/sorenewals/error1.png";
import error2 from "../images/sorenewals/error2.png";
import users from "../images/sorenewals/users2.png";
import users2 from "../images/sorenewals/users3.png";
import users3 from "../images/sorenewals/users4.png";
import userJourney from "../images/sorenewals/userjourney.png";
import sketches from "../images/sorenewals/sketches.png";
import flow from "../images/sorenewals/flow.png";
import lofi from "../images/sorenewals/lofi2.png";
import system from "../images/sorenewals/system.png";
import Emily from "../images/sorenewals/emilytesting.png";
import { Details, Blockquote } from "../components/details.js";
import MoreProjects from "../components/moreProjects.js";
export const pageQuery = graphql`
  query {
    allMdx(
      filter: { frontmatter: { company: { ne: null } } }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            shortTitle
            path
            type
            order
            tags
            company
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  height: 280
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className="header">
  <h1 className="purple">ServiceOntario Renewals</h1>
  <h2>{props.pageContext.frontmatter.title}</h2>
    </div>
    <p>{`The province of Ontario has several identification cards such as driver's licences, health cards, and photo cards which require renewals every few years. As a product design intern at the Ontario Digital Service, a division of the Government of Ontario, I worked with my team to redesign the web platform used by 5 million people annually to ensure it is logical and accessible, as well as increase overall completion rates.`}</p>
    <Details company={props.pageContext.frontmatter.company} role={props.pageContext.frontmatter.role} timeline={props.pageContext.frontmatter.timeline} skills={props.pageContext.frontmatter.tags} mdxType="Details" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6b728fdf9d401458d3e846cd3a01e749/9b379/header.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAADOUlEQVQ4y02TT2gcdRTHFzx50puoF6WePAVEqQaqB/EgomhvokjAimDF2OKfXNqIXmyoCkUCWqJtKEUsKJZim0SSxqTRNEmzm00zMzszOzuz+5s/u/NvZ3aT/edHdhPBB18evMf38X3/MlIgUUyLiLZAdAR216HULCGHMkqsYO6aiI5NpS0ot8poicaOv4OeFg9ilYEv7ZaQQomMGqmc/nycibMTLC79yfmp87z3/nGkqsTsygwffjDKzMwNbi4sMPbxJ1z86QJ21+b0+Cm+PXeO9bU1Lk1Pc/LECbbFNpm77g7H3j7G8DPDjIyMMDQ0xOEnD7OhbTB15QIPPvYITzz7NE89f4TMvfdwfGwUr+Nx5OUXeODxR3nutRe579BD3H/oYVYLa2S0SEdWFLLZLLmtHKZpktRTnFRwZXOWVyc/5c2pU7wy+RHDE+8wMXcRpyEY/fkb3vhhnLd+/IyXJk9y9PsxsvY2mWJcRFVVcrksBbmAohToWyWtUI4cTLtKEIcEUUiSpNT3EozYwPEjgigiiiOiKKLdaiNSQaZY19Eljfk/lpj/6wY3V28zt7yOEeqIUFAuWaRJSrvVotvukOwl6KFOmiTEUUwYhjQaDXrdHla9TEaPNYQlWL61zNLGAnlZIq8UEY0KOXULXdXpdrv0er2B8qSVokc6rudiWRaO49BsNum0O1h1qz9DjZrv43pVRMVBCHtALKdlDNfAtj3iesLuXovePwxa7hfscxzXQ9gOXs0nSRv7CvtLaaURqS+IPIteM9yfYVNwV88TCIP/2143pZSUWF6X+G76d+ZXd1jLq2xtaph9haW6xtVswJnrgskFl6/mXC7/HeI2LbYdhy+ve5yddfh6zuGLaw6rRhW/a3P5l1mOvv4uC7c2yebz5PMKZl+hEWssyjHTKx6/bvhcWnGZzcfYqYlWc7l6J+BatjbAb3cCZMenkpZQTZvF2zkks4JkGJSrtf2WC4GCU/OJgoBuIxyg04jRAo2SbyEcn2Y9oJ32cxG12EUJCoNzqcd1qp43OJv+lo3IINP/S6mqIHsqcvU/FAb/ulOTkA7iygGkmjzISZ6E7MqovkYxLFKo7XP+BUdyfaptd7+tAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ServiceOntario Renewals header image",
            "title": "ServiceOntario Renewals header image",
            "src": "/static/6b728fdf9d401458d3e846cd3a01e749/1cfc2/header.png",
            "srcSet": ["/static/6b728fdf9d401458d3e846cd3a01e749/3684f/header.png 225w", "/static/6b728fdf9d401458d3e846cd3a01e749/fc2a6/header.png 450w", "/static/6b728fdf9d401458d3e846cd3a01e749/1cfc2/header.png 900w", "/static/6b728fdf9d401458d3e846cd3a01e749/9b379/header.png 951w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4>{`Context`}</h4>
    <h2>{`Problem`}</h2>
    <p>{`There currently is a online renewal platform for some of the identification cards issued by the province which was created to be an efficient alternative to the long, dreaded waits associated with the in-person process. However, analytics have shown that the process's completion rates are less than satisfactory and sentiment from the public is mediocre at best especially regarding accessibility. This is because people run into processing errors while using the online service which force them to complete the transaction in person. These issues are summarized by the following:`}</p>
    <div className="list">
  <h3>1. Low transaction completion rates</h3>
  <h3>2. Negative sentiment surrounding the platform and process</h3>
  <h3>3. Accessibility issues using the platform</h3>
    </div>
    <h2>{`Breaking down the goal`}</h2>
    <p>{`To further understand the frustrations that Ontarians were having with the platform, our team analyzed over 1,500 comments submitted through the current service's feedback form. The main pain points and opportunities to improve them were:`}</p>
    <div className="list">
  <h3>
    1. It was difficult finding the correct service to renew their products.
  </h3>
  <p>
    <strong className="purple">Opportunity: </strong>Combine the standalone
    services into a single renewal service.
  </p>
  <h3>
    2. Ontarians were confused by the questions being asked and the redundancy
    of information being asked of them.
  </h3>
  <p>
    <strong className="purple">Opportunity: </strong>Simplify the content, and
    only ask what is necessary.
  </p>
  <h3>
    3. When running into an error that prevents them from continuing the
    process, Ontarians could not figure out why, nor what to do next.
  </h3>
  <p>
    <strong className="purple">Opportunity: </strong>Thoroughly explain error
    states and suggest next steps to rectify the situation.
  </p>
    </div>
    <Accordion allowZeroExpanded mdxType="Accordion">
  <AccordionItem mdxType="AccordionItem">
    <AccordionItemHeading mdxType="AccordionItemHeading">
      <AccordionItemButton mdxType="AccordionItemButton">
        Learn more about the pain points
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel mdxType="AccordionItemPanel">
      <h3>1. Discoverability and correct platform</h3>
      <p>
        A notable number of feedback responses by Ontarians cited the inability
        to renew their identification due to not being able to find the service
        that renews for their specific case.
      </p>
      <p>
        Currently, Ontario has two platforms to renew ServiceOntario products.
        This includes the{" "}
        <a className="link" href="https://www.services.gov.on.ca/wps85/portal/s2i/!ut/p/z1/hZDLasMwEEW_pQsvpdHIsix15xYamkIfhDaxNkZ2VNvgSEFxYvL3deiq0JBZzePee2DAwAaMt6e-tWMfvB3muTSyUgsmeSHwlS0R2cfLs5IPnxl_YwhfsL4lMfOZXamCzX5zS1LOiPwqYpHC6pJRtRZKTlFnKWdSCYqYS6FSrSVFyXKFHLOcIGohhMryjGKmU-Sac37xD9a3UDoPK-erxwKWYNoh1L9fKHydqhZMdN8uukiPcV5347g_3CcsYdM00eBHG_tAG5uwvW1dwqLzbiJ1GDtyDsdIOmeHuW9s3BLrt2Qb-5OLBzL0jfONI8EPvXf_4bpwGGHzlwL73ZN-3-1UKkx9noq7H7_yT1w!/dz/d5/L2dBISEvZ0FBIS9nQSEh/" target="_blank" rel="noreferrer">
          standalone driver's licence renewal
        </a>{" "}
        and the{" "}
        <a className="link" href="https://www.services.gov.on.ca/wps85/portal/s2i/!ut/p/z1/hZDLTsMwEEW_hUWW9owd52F2EeIhFtCqFBpvIicdkkipXblpo_49qVghUXVW87j3HmnAwAaMs6e-tWPvnR3muTRplT9jKgsl3t4_1hqXCb6I5SKL8RHhE75uScx8xitV4Ow3tyTljMiuItYIq0tG1VooJZexEhpRciGyVOWx1ikXKWa5kCLJmBBaKZUnWcJFomMhtZTyYh-sa6EkByty1UMBr2Dawde_TyhcHectmEDfFCjwY5jX3TjuD_cRRjhNE_dutKH3vLER7m1LEQZyNLHajx07-2NgHdlh7hsbtsy6LduG_kThwIa-IdcQ827oHf2H6_xhhM1fCux3T3qx2-WxMvV5Ku5-APOlIF0!/dz/d5/L2dBISEvZ0FBIS9nQSEh/" target="_blank" rel="noreferrer">
          integrated driver's licence and health card renewal
        </a>{" "}
        platforms. While it may seem easy enough since there are only two
        platforms, one of the main issues lies in the labelling of these
        platforms when they are discovered.
      </p>
      <p>
        Ontario has a series of information pages that explain their products
        which is also explains how to renew these products. These pages are
        often the place Ontarians will go to find the ultimate online renewal
        platforms which is a problem because of how some of the call-to-action
        buttons are labelled.
      </p>
      <div className="row">
        <div className="column column-img">
          <img src={callToAction} alt="The call to actions contradicted the content" className="no-border-img" />
          <p className="caption">
            The call to actions and page headings were different, causing users
            to find the incorrect service for their needs.
          </p>
        </div>
        <div className="column">
          <p>
            For example, on this page, the user is looking to only "renew your
            driver's licence" as indicated by the main heading. However, the
            call to action shows that you can renew your driver's licence or
            health card. Firstly, this is confusing as it takes you to a
            platform where you need both of these products in order to renew
            successfully, and users may not have both in every situation.
          </p>
        </div>
      </div>
      <p>
        Secondly, there is a another renewal platform that supports the renewal
        of only a driver's licence, why not link them there instead? Lastly,
        there is a separate page talking about renewing both a driver's licence
        and a health card at the same time, so the call to action on the "renew
        a driver's licence" page would be better.
      </p>
      <p>
        Thus, there is an opportunity to improve the discoverability and linking
        to these platforms through consistent call to actions. Taking this a
        step further, combining all these services into one makes it so that it
        is impossible to find the "incorrect" service, helping to address this
        prominent concern from users.
      </p>
      <br />
      <h3>2. Redundant and confusing questions</h3>
      <div className="row">
        <div className="column">
          <p>
            Analyzing the feedback from Ontarians also raised the question as to
            why our platform asks for the same information more than once from
            our users, as well as why the language being used is so complicated
            to understand.
          </p>
          <p>
            For example, the user is asked for address information in the second
            step, and then also asked again for their postal code in the third
            step. This causes duplicate entries of information, making users ask
            why they needed to do that twice.
          </p>
        </div>
        <div className="column column-img">
          <img src={redundancy} alt="Questions were repeated and confusing" className="no-border-img" />
          <p className="caption">
            The questions were repeated and confusing to users.
          </p>
        </div>
      </div>
      <p>Also, there were questions with convoluted language such as:</p>
      <Blockquote text="Do you suffer from, or has a physician ever advised you that you suffer from any medical condition (including, but not limited to, heart disease, stroke, diabetes requiring insulin to control, epilepsy, seizure disorder, or any condition that may result in loss of consciousness or awareness) or any physical disability or functional impairment that may affect your safe operation of a motorized vehicle?" mdxType="Blockquote" />
      <p>
        For me, although I read the sentence more than once, it was still
        difficult to understand what it is trying to ask since it seems to ask
        three questions in one long blurb. It is questions like these that users
        seemed to stumble on and possibly answer incorrectly.
      </p>
      <p>
        While I understand that some of questions are asked for verification reasons by the Ministry of Health to prevent fraud, I strongly believe that many of the questions are unnecessary or could be improved upon to make it easier for the common person to renew.
      </p>
      <p>
        As such, there is opportunity to improve by removing duplicate questions, and simplifying the content to be more understood by the general population.
      </p>
      <br />
      <h3>3. Errors missing valid explanations</h3>
      <p>
        Ultimately, the errors that were provided to users when they encountered
        a barrier preventing them from completing the transaction online was the
        area with the most room for improvement.
      </p>
      <p>
        Below are three different error messages that appear at different points in time depending on when the user encounters an error and for what reason. All three of them have a few similar traits:
        <ul>
          <li>
            Lack of a core reason as to why a user cannot complete the transaction online
          </li>
          <li>
            Repeated verbiage that does not fit the user's unique situation
          </li>
          <li>
            Vague next steps when asked to "visit your nearest ServiceOntario centre"
          </li>
        </ul>
      </p>
      <br />
      <img src={errors} alt="The vague errors provided to users" className="no-cursor no-border-img" />
      <p className="caption">
        The errors shown were vague, providing little context as to why they
        cannot complete the transaction online and cited often in the responses
        to the feedback form. I believe that the
      </p>
      <p>
        This provides an opportunity in the redesign to integrate clearer reasons for error that do not blanket all users, as well as provide more direct next steps for the in-person renewal process.
      </p>
    </AccordionItemPanel>
  </AccordionItem>
    </Accordion>
    <p>{`These findings presented the opportunity to explore the following refined problem statement and goal for our solution:`}</p>
    <Blockquote text="How might we redesign the online renewal platform to be a one-window transaction flow that promotes usability and inclusion?" mdxType="Blockquote" />
    <p>{`We will know when the goal has been met with the following key performance indicators:`}</p>
    <div className="list">
  <div className="kpi">
    <img src={kpi} alt="kpi" />
    <h3>Reduce time spent on task</h3>
  </div>
  <div className="kpi">
    <img src={kpi} alt="kpi" />
    <h3>Increase transaction completion rates</h3>
  </div>
  <div className="kpi">
    <img src={kpi} alt="kpi" />
    <h3>Increase in positive feedback and overall sentiment</h3>
  </div>
    </div>
    <p>{`While the in-person process for ServiceOntario renewals is also important, the scope of the project was limited to refining the online platform. However, to create a holistic solution, we considered the end-to-end service to ensure our online redesign is logical and easy to implement with consideration to the in-person service.`}</p>
    <h2>{`Our approach`}</h2>
    <div className="row">
  <div className="column-3 row">
    <div className="column-1">
      <h3 className="purple">1.</h3>
    </div>
    <div className="column-9 column-list">
      <h3 className="purple">Research</h3>
      <p>Analyze user feedback</p>
      <p>Site audit</p>
      <p>Personas</p>
      <p>Journey mapping</p>
      <p>Empathy mapping</p>
    </div>
  </div>
  <div className="column-3 row">
    <div className="column-1">
      <h3 className="purple">2.</h3>
    </div>
    <div className="column-9 column-list">
      <h3 className="purple">Design</h3>
      <p>User flows</p>
      <p>Low-fi prototypes</p>
      <p>Hi-fi designs</p>
      <p>Interactive prototype</p>
      <p>Coded prototype</p>
    </div>
  </div>
  <div className="column-3 row">
    <div className="column-1">
      <h3 className="purple">3.</h3>
    </div>
    <div className="column-9 column-list">
      <h3 className="purple">Evaluate</h3>
      <p>User testing</p>
      <p>Design iterations</p>
      <p>Reflection</p>
    </div>
  </div>
    </div>
    <p>{`Each week, we followed the process of plan, design, and test for six weeks. With this approach, we redesigned the existing web platforms into one consolidated service that allows users to renew all of their ServiceOntario products at once. It also streamlines the process, helping users "fail faster" and understand why there are errors if any arise. `}<AnchorLink to="/sorenewals#solution" title="Solution" className="link" mdxType="AnchorLink">{`
Jump to the solution.`}</AnchorLink></p>
    <h4>{`Research`}</h4>
    <h2>{`Understanding the user journey`}</h2>
    <p>{`First, I familiarized myself with the current platform and process for renewing one's driver's licence and health card. I completed a site audit to trace the different entry points, only to find that many entry points had contradicting call-to-actions that led to an unexpected service. For the final design, we made sure to include fewer, as well as consistent entry points to the platform.`}</p>
    <p>{`By going through the process myself, I encountered each of the pain points users faced when using the platform. It really showed me how difficult the service was to use, and how people who have more barriers than I do will not be able to use the service to its full extent.`}</p>
    <p>{`Next, we created four personas to see how diverse our target audience is. These were informed by analyzing the feedback received on the current platform. This process also included individual journey maps, empathy maps, and an overall, consolidated user journey map which outlines the design of the entire service. `}<br /><br /></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/095488b45cf23592ce58db698d14dce7/3acc6/users.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.22222222222222%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABnElEQVQoz0WQW47TQBBFvd354IdFsBAkFIT4YQHASBHioQExoIntPBwnzsRuu912+6Fxx3YOcgdCSaVb99btqlY5y2JJUAdsm4C1XuPnvsVJ21Qbpr6vfFblymrWo3ybU2+tN1fuKQ+nO3UE4ZaF7yGylClUkbNcrwj3ezrTceZM0zas1ktEKiwfxoHp7eEY0Y+9zaAMcM6AOB64+/SBslB24NOT4evnOd+/zRnHs9XMqefH73uUyvkXUmlu518YhtFyO3Aq8uUdsxfPqFR2Nb95NePd29mVG9OzeP0Sk8WMf7X3H+fc3DxH163lm2KDY06GQmbEUUjbtgzDYDHcRcSJxBhjzW3TUPsetczox9H6jscjP+9/oXVluf1h09RkmSRXBVJK+r4njmOi/Z79LiTPc7quQyQCqTXmdLIL6rpmt9vxeHjkEEV2cahDnLIqSUSCSBN78On4qUzJVU6SCqqmom5rYhGTiJjJ3w89qlTWn2YpSZqga81Wb3HczMXLPTx5yUW2sOhK94KZe9EmT37pP4iH/33pXvWp/gPwi1KqD9/XBQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "User research and journeys created for the project",
            "title": "User research and journeys created for the project",
            "src": "/static/095488b45cf23592ce58db698d14dce7/1cfc2/users.png",
            "srcSet": ["/static/095488b45cf23592ce58db698d14dce7/3684f/users.png 225w", "/static/095488b45cf23592ce58db698d14dce7/fc2a6/users.png 450w", "/static/095488b45cf23592ce58db698d14dce7/1cfc2/users.png 900w", "/static/095488b45cf23592ce58db698d14dce7/21482/users.png 1350w", "/static/095488b45cf23592ce58db698d14dce7/d61c2/users.png 1800w", "/static/095488b45cf23592ce58db698d14dce7/3acc6/users.png 2397w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  My team and I created four main personas to better understand our users and
  their needs for the platform.
    </p>
    <Accordion allowZeroExpanded mdxType="Accordion">
  <AccordionItem mdxType="AccordionItem">
    <AccordionItemHeading mdxType="AccordionItemHeading">
      <AccordionItemButton mdxType="AccordionItemButton">
        Learn more about the user research we did
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel mdxType="AccordionItemPanel">
      <p>
        The user personas helped us understand different groups of Ontarians
        through their backgrounds and situations, as well as their goals. This
        helped us get into heads and understand their{" "}
        <a href="https://uxdesign.cc/8-things-to-use-in-jobs-to-be-done-framework-for-product-development-4ae7c6f3c30b" className="link" target="_blank" rel="noreferrer">
          jobs to be done
        </a>
        .
      </p>
      <p>
        This helped me understand what our personas had to do to complete their
        ultimate goal of renewing their ServiceOntario products. There are three
        major steps that can occur:
      </p>
      <div className="list">
        <h3>1. Receiving notification to renew their products.</h3>
        <h3>2. Attempt to renew their products online.</h3>
        <h3>
          3. If unsuccessful at step 2, then visit an in-person ServiceOntario
          location.
        </h3>
      </div>
      <p>
        For each of these steps, we looked into the possible scenarios and
        intricacies of the persona's individual situation. I analyzed the
        actions at each step and looked for opportunities to improve upon it.
        This resulted in four individual user journeys.
      </p>
      <a href="https://miro.com/app/board/o9J_kuedVQ8=/" target="_blank" rel="noreferrer">
        <img src={users2} alt="User journeys for each persona" />
      </a>
      <p className="caption">
        Each persona warranted an individual user journey.{" "}
        <a href="https://miro.com/app/board/o9J_kuedVQ8=/" className="link" target="_blank" rel="noreferrer">
          See it in detail.
        </a>
      </p>
      <p>
        From completing this exercise, we also were able to connect with the
        personas and understand the existing user journey from their
        perspective. This resulted in creating four empathy maps that lay out
        what our personas would see, hear, feel, and be frustrated with about
        the current platform.
      </p>
      <br />
      <a href="https://miro.com/app/board/o9J_kuedVW8=/" target="_blank" rel="noreferrer">
        <img src={users} alt="Empathy maps for each user persona" />
      </a>
      <p className="caption">
        We created an empathy map for each user persona.{" "}
        <a href="https://miro.com/app/board/o9J_kuedVW8=/" className="link" target="_blank" rel="noreferrer">
          See it in detail.
        </a>
      </p>
      <p>
        We summarized the findings from each of these personas into a very
        comprehensive, overall user journey. This showed us the entire service
        and all components from the moment you get a reminder to renew your
        product to finishing a renewal in-person.
      </p>
      <br />
      <img src={users3} alt="Overall user journey" className="no-cursor" />
      <p className="caption">
        The overall user journey map gave us an idea of the entire service.
      </p>
      <p>
        Afterwards, we looked at other governmental organizations to see how
        they offer their renewal services. This gave us an idea of how they may
        or may not have made their platform easy for their users, allowing us to
        implement parts of these ideas into our own service.
      </p>
      <br />
      <iframe src="https://airtable.com/shrmJFfYnVN0tZPcH/tblxgsiVAIN7Ehsax?backgroundColor=purple&layout=card" frameBorder="0" width="100%" height="450"></iframe>
      <p className="caption">
        We analyzed different platforms for interesting features.{" "}
        <a href="https://airtable.com/shrmJFfYnVN0tZPcH/tblxgsiVAIN7Ehsax?backgroundColor=purple&layout=card" className="link" target="_blank" rel="noreferrer">
          Explore the AirTable here.
        </a>
      </p>
    </AccordionItemPanel>
  </AccordionItem>
    </Accordion>
    <p>
  From this process, I learned to think systematically about the problem at
  hand, and ensure I take into account the entire design of the service from the
  digital platform to the in-person renewal process.{" "}
    </p>
    <h4>{`Design`}</h4>
    <h2>{`Prototyping the golden path`}</h2>
    <p>{`By understanding the user journey of the current platform, it made crafting the new user flow easier since the specific pain points are already identified.`}</p>
    <p>{`We explored a one-window approach that consolidates all of the current services. It removes the confusion when Ontarians currently face when trying to find the correct service to renew their product, and allows them to renew everything at once rather than having to complete more than one transaction.`}</p>
    <p>{`From here, we focused on developing a high level flow chart to take advantage of common design patterns to make the transaction process familiar and easy. Logically, it made sense to keep the platform similar to a wizard-like form and use the card information as a login-type credential to check eligibility right away.`}<br /><br /></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/628dfd9f9f168263b87967a9b75413b9/3acc6/userflow1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.55555555555556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABT0lEQVQoz5WPW0vkQBCF8///k7Iogsjuy1z6kqTTSWZmszNJV3XmojAPdSRNFBV92IfD11Ucmq8yP3pMKbiAjx4556hnOnZpX8UKLrqU9+7M5thMlHqsUcZSsvgcwWfGhjcYTyM81zien1GRx+7vDr73iOeA/vQP++MefGK03GI8jmhig+v1ija2cnm5oL/0kk0WjhwKmkxquEGBDgu4oFFxjYJyrLsD1L6Dm2y5Qk45PPuPlGlfcimZDhqaNCauSaPsF6D2BuWwxDpoWNa4s1s8+i0sK6i5r4L6SEkMWjJDBilBQ5GFG5agzS+4sEqzZYNHv8fvTZc+12Huf468MZsHMUGLIiNuWArt7sWFlahgxJKWp+Ygf7adWFaiw9z/knQlfWeYTr6FGyZDkwwfig5PzQ55/E9DTUZsUOKHhdh5NqRldXCy6sv0/snw7cNXZ3FFwMOwQ08AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "The initial user flow for the application",
            "title": "The initial user flow for the application",
            "src": "/static/628dfd9f9f168263b87967a9b75413b9/1cfc2/userflow1.png",
            "srcSet": ["/static/628dfd9f9f168263b87967a9b75413b9/3684f/userflow1.png 225w", "/static/628dfd9f9f168263b87967a9b75413b9/fc2a6/userflow1.png 450w", "/static/628dfd9f9f168263b87967a9b75413b9/1cfc2/userflow1.png 900w", "/static/628dfd9f9f168263b87967a9b75413b9/21482/userflow1.png 1350w", "/static/628dfd9f9f168263b87967a9b75413b9/d61c2/userflow1.png 1800w", "/static/628dfd9f9f168263b87967a9b75413b9/3acc6/userflow1.png 2397w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">A high level overview of the user flow.</p>
    <p>{`While prototyping, we made sure to take a mobile-first approach to allow for scalability. For the first week, we started with Balsamiq to map the golden path (putting error states on hold) of renewing both a driver’s licence and health card at the same time. During week two, we added the ability to renew driver’s licences and health cards individually.`}<br /><br /></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b5b7b2d1a7407da3fd57c2616064c213/02b44/lofi.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.888888888888886%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAABJElEQVQY0zXH6U7CQAAA4b7/GylRDAp44FHctlCpZdvtbu8tl4qSjBH1x+TLOE/ZE313wHB+Te+hz2g+5nRyznV4w8X0ktFszEAMuRQDroIRffeCq2DM2WOf4fyG3uSMUXjLyV2PSeLi+MZDiGeSMqGsC2QpMYUhMjGxUsgiQVcGN1oililRFLI0ile5QFUpOlcklTy+bCVOWC14cH2KdcMByNc17/sDcZWSFgX2rWX7sWH3tWf3+YHp3jCrDWm9QdmOvNujOouu34mtxvGqkHEkEXnMNEvxipipTokqQ9KsUW1J2a0w2wy9ych3Gr1VLK0mtgnSal5tQroqWNgYZ9YGhNZn1v7kEfwpquDYY/bCvYrwGw+v9hD1rz//X9D4eI04+g0tyGj4m2dA2wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Low fidelity prototypes from weeks one and two",
            "title": "Low fidelity prototypes from weeks one and two",
            "src": "/static/b5b7b2d1a7407da3fd57c2616064c213/1cfc2/lofi.png",
            "srcSet": ["/static/b5b7b2d1a7407da3fd57c2616064c213/3684f/lofi.png 225w", "/static/b5b7b2d1a7407da3fd57c2616064c213/fc2a6/lofi.png 450w", "/static/b5b7b2d1a7407da3fd57c2616064c213/1cfc2/lofi.png 900w", "/static/b5b7b2d1a7407da3fd57c2616064c213/21482/lofi.png 1350w", "/static/b5b7b2d1a7407da3fd57c2616064c213/d61c2/lofi.png 1800w", "/static/b5b7b2d1a7407da3fd57c2616064c213/02b44/lofi.png 3358w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  We created low fidelity mockups to test user flow and information
  architecture.{" "}
    </p>
    <Accordion allowZeroExpanded mdxType="Accordion">
  <AccordionItem mdxType="AccordionItem">
    <AccordionItemHeading mdxType="AccordionItemHeading">
      <AccordionItemButton mdxType="AccordionItemButton">
        See more wireframes and progress photos
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel mdxType="AccordionItemPanel">
      <img src={sketches} alt="The call to actions contradicted the content" className="no-cursor" />
      <p className="caption">
        One of the sketches I did the get a sense of the flow and basic
        interface.
      </p>
      <img src={flow} alt="The call to actions contradicted the content" className="no-cursor" />
      <p className="caption">
        As a team, we mapped all the possible paths to help with creating a
        solid logic flow.
      </p>
      <img src={lofi} alt="The call to actions contradicted the content" className="no-cursor" />
      <p className="caption">
        After creating and user testing low fidelity mockups in Balsamiq, we
        identified satisfied and unsatisfied parts of the platform each week.
      </p>
      <img src={system} alt="The call to actions contradicted the content" className="no-cursor" />
      <p className="caption">
        When moving to higher fidelity, we created a variation of the{" "}
        <a href="https://designsystem.ontario.ca" className="link" target="_blank" rel="noreferrer">
          Ontario design system
        </a>{" "}
        at the time to help make prototyping more efficient.
      </p>
    </AccordionItemPanel>
  </AccordionItem>
    </Accordion>
    <p>{`In week three, we migrated to Figma and worked on guiding Ontarians when they deviated from the golden path. In the final week, we tested a coded prototype that included the option to renew Ontario photo cards and error states, too.`}<br /><br /></p>
    <iframe style={{
      "border": "1px solid rgba(0, 0, 0, 0.1)"
    }} width="100%" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FQjWeJZjwAGUw9MefKOHhsA%2FSO-Renewals-Website-Copy%3Fnode-id%3D718%253A23227" allowFullScreen></iframe>
    <p className="caption">
  In the end, we had over 256 artboards in total for all of the possible paths!
    </p>
    <p>{`It was really important for us to include the ability to renew an Ontario photo card in this renewal service. This is because the Ontario photo card was initially created to help 1.5 million Ontarians access and obtain a valid form of identification since they do not have a driver's licence. Currently, there is no online service to renew this product – thus, a product meant to be accessible and have similar privileges as a driver's licence, truly is not meeting its initial goal. By including it in this prototype, we are advocating for its inclusion in the next iteration of the renewal platform.`}</p>
    <h4>{`Evaluate`}</h4>
    <h2>{`User testing at the busiest ServiceOntario`}</h2>
    <p>{`Each week, we took what we were working on and tested it with Ontarians at the busiest ServiceOntario location in Toronto. We asked individuals who were renewing their driver’s licences, health cards, or both, to try out our redesigned platform. For the first two weeks, we tested with a Balsamiq prototype, while in the last two weeks, we used a combination of the Figma prototype and the coded website. In total, I led 11 guerilla tests with Ontarians.`}<br /><br /></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/90df3a5202148f288a848bfb0cfb62c4/3acc6/usertesting.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.11111111111111%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB6klEQVQY0x3MT0iTcRwH4LdL1CFodlpZh3DioVNaIDoTNYZUFhGGzCxktdYyRtMyLCwqVploaRlRzJYN2XBtvmPLd3s3N3N7bcxXN99tRRLWodkfOv8u30/U+YGH+/SnAD42hSnRA/+cH3zUDef0OOIrUSSUKJIZAYWvEjyiCw7fKwgJL+YLc5iYtiORfIv3sUnMhh2UTHqQ/5Uj7uP6Mq5caEP1/kroGrXoONGMw/VVsA31I58NQOIfY/7dc3QZjsHcVoebN0y4PzYAjtsAY9UODDXtxmBDKdnPNSJXXCYuV8xA9Dvx6EEP7vadwdMBK57ZrBBmXVj7EsfqggNX7wzCNhLCrWtW9J83YPTFMDZu2gx90z44LCfxpEVDE5cOQSlmiIvn4nS5q5MmbSbq7h2li9dHaPyhmVLyDCkZH8V8w7S3Rks63XGy6Fvp3lk9vfE6qaRERaYjNdTRfoq6tbvopfEg/Q9jShxl5RpU7qlAXYsR1bUHYG6thZu3I51yodfSifIKDdRqFcq2b4Ph9FG4Q17UNzRji2or1DtLcbunHa/H+rCyngWn/FBYWA6zYGqGBSQfE9MBFvwQZAurEpPXUiyyFGGhxRAT0iHGSwEmyCJb/CYz6fM/E1lkKcyU7zIr/Myy/O88+wvJ/UICBiNfTAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Guerilla user testing sessions I conducted at Bay and College's ServiceOntario location",
            "title": "Guerilla user testing sessions I conducted at Bay and College's ServiceOntario location",
            "src": "/static/90df3a5202148f288a848bfb0cfb62c4/1cfc2/usertesting.png",
            "srcSet": ["/static/90df3a5202148f288a848bfb0cfb62c4/3684f/usertesting.png 225w", "/static/90df3a5202148f288a848bfb0cfb62c4/fc2a6/usertesting.png 450w", "/static/90df3a5202148f288a848bfb0cfb62c4/1cfc2/usertesting.png 900w", "/static/90df3a5202148f288a848bfb0cfb62c4/21482/usertesting.png 1350w", "/static/90df3a5202148f288a848bfb0cfb62c4/d61c2/usertesting.png 1800w", "/static/90df3a5202148f288a848bfb0cfb62c4/3acc6/usertesting.png 2397w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">We tested with real Ontarians every week.</p>
    <Accordion allowZeroExpanded mdxType="Accordion">
  <AccordionItem mdxType="AccordionItem">
    <AccordionItemHeading mdxType="AccordionItemHeading">
      <AccordionItemButton mdxType="AccordionItemButton">
        Learn more about my experience guerilla testing
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel mdxType="AccordionItemPanel">
      <p>
        As an introvert, user testing was not in my comfort zone, let alone
        guerilla user testing. The thought of approaching a stranger and asking
        them to try out our application when there is a chance that they might
        not like it was terrifying to me. I got nervous from just watching my
        colleagues conduct the interviews.
      </p>
      <p>
        For the first one that I conducted on my own, I stumbled and stuttered
        at the beginning. However, after showing the prototype and having them
        walk me through their thoughts on it, I had calmed down, learned to
        listen intently, and started asking inquisitive questions. Ultimately,
        while it was scary at first, I quickly learned that it was not as bad as
        my mind was making it out to be.After conducting a few on my own, I
        graduated to helping others also conduct guerilla testing.
      </p>
      <p>
        I found it an enlightening experience as we got to survey a lot of
        different people in a really short period of time. The results were
        interesting too, as we got a lot of diverse and helpful feedback to help
        us iterate on the prototype.
      </p>
      <p>
        I am thankful for getting the opportunity to try guerilla user testing
        and even conduct numerous sessions myself. I hope to continue using this
        technique in the future as it is a great way to get a variety of
        feedback quickly.
      </p>
    </AccordionItemPanel>
  </AccordionItem>
    </Accordion>
    <p>{`In the earlier weeks, from testing, I learned the following:`}</p>
    <div className="list">
  <h3>1. People wanted a brief overview of what to expect from the process.</h3>
  <h3>
    2. They were sometimes confused by the language that was being used, leaving
    them puzzled about what they needed to do.
  </h3>
  <h3>
    3. When encountering errors, Ontarians needed more explanation and next
    steps.
  </h3>
    </div>
    <p>{`Hearing these concerns, it felt like we were back at square one with our initial problem. But after each session, the team got together to make changes and implement the feedback we had heard. Eventually, we validated our changes in the following guerilla user testing sessions.`}</p>
    <p>{`One of the most notable changes we made was in our third week when we noticed Ontarians were getting frustrated when faced with an error late in the renewal process. I realized that we could help them “fail faster” so they can learn more quickly that they do not qualify for renewing online and not their waste time. We reversed part of our flow so the eligibility questions are earlier in the process rather than the end.`}<br /><br /></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a871bd4d1247ac0e69314e1762be3046/3acc6/userflow2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.55555555555556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABNklEQVQoz42OUW+bMBhF+f8/au2mantrlLXBxoATDLQNhM8mJGuVB84EIg/VVikPV1ey7nd8oqIv2IYtuc9xR0fqU6pjRSop5dGR+Yxd2GG9nXdueZs3ftqUU48LZ4yG9wE5CXWo6YceFwo+3s8UoaAfTpShot7XlG1JMzSEU8B5xzAMuOC4XC6UoRzPH2e6P90Y5SGf7YykWJ+hDpZ1JaRdxmH/SHHYkPl8Np6SSYYRs9yY2dqIGW2wk/kYxV3MFNUptGz4/ZbzTbXo1iD1PUWzYiMatWyuud4sPV470qLR84EmkZjnxvLT9ujGIC8/2DUrYknQ84f6q4zX/gTUC/DX1qPblO71gaJd3wRUov4FToZPe8v3xJO0Kb66w91o+F+gFkV8MKzfHLpLcO2KrNugps2NwL+rM0aA0BPvCAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "The revised user flow for the application",
            "title": "The revised user flow for the application",
            "src": "/static/a871bd4d1247ac0e69314e1762be3046/1cfc2/userflow2.png",
            "srcSet": ["/static/a871bd4d1247ac0e69314e1762be3046/3684f/userflow2.png 225w", "/static/a871bd4d1247ac0e69314e1762be3046/fc2a6/userflow2.png 450w", "/static/a871bd4d1247ac0e69314e1762be3046/1cfc2/userflow2.png 900w", "/static/a871bd4d1247ac0e69314e1762be3046/21482/userflow2.png 1350w", "/static/a871bd4d1247ac0e69314e1762be3046/d61c2/userflow2.png 1800w", "/static/a871bd4d1247ac0e69314e1762be3046/3acc6/userflow2.png 2397w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  We revised the user flow to help Ontarians "fail faster" after our third week
  of testing.
    </p>
    <Accordion allowZeroExpanded mdxType="Accordion">
  <AccordionItem mdxType="AccordionItem">
    <AccordionItemHeading mdxType="AccordionItemHeading">
      <AccordionItemButton mdxType="AccordionItemButton">
        Learn more about why we revised the user flow
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel mdxType="AccordionItemPanel">
      <p>
        Our initial thought was to use the card identification numbers as a
        login credential so that if users fail at this step, then they do not
        have to worry about answering questions afterwards.
      </p>
      <p>
        However, after testing, we realized that inputting the card
        identification numbers was the most difficult step of the process and
        most time consuming as well. Having this step right at the beginning led
        to disappointment when users did not select eligible answers after
        completing this step and were unable to complete the process.
      </p><br />
      <img src={userJourney} alt="User journey after week three of testing" className="no-cursor" />
      <p className="caption">
        After week three of user testing, we plotted the user's satisfaction along the user journey. <a href="https://www.figma.com/file/U3R247geW3ObC4LkpsJNXn/SO-Renewals-%E2%80%93-User-journey-after-week-3?node-id=0%3A1" className="link" target="_blank" rel="noreferrer">See it at full size.</a>
      </p>
      <p>
        Thus, we reversed the user flow so that the yes or no questions act as
        a quick eligibility check before getting to the more time consuming steps, essentially helping Ontarians "fail faster" and course correct to the path they need to be on. This allowed for those who would fail on the short questions to quickly learn that they cannot complete the process online and not waste time by getting stopped after completing longer steps.
      </p>
    </AccordionItemPanel>
  </AccordionItem>
    </Accordion>
    <h2>{`Implementing the feedback`}</h2>
    <p>{`In our final sprint, we reflected on everything we learned, made changes based on user feedback, and finalized the loose ends.`}</p>
    <p>{`An important change we made included working with the constraints of a vertical layout such as a mobile phone. We found Ontarians would stop scrolling and get lost during the process, resulting in us modifying the layout so that the most important information and call to action buttons are displayed first or more prominently on the screen. This involved breaking up sections into shorter pages, and rearranging elements on the page.`}</p>
    <p>{`We worked on breaking down the onboarding of the application into the bite sized pieces. As everyone knows and as Ontarians that we tested reiterated:`}</p>
    <Blockquote text="“Nobody reads the terms and conditions.”" mdxType="Blockquote" />
    <p>{`But as a government service, it is important for the user to understand all parts of the process and know what they are signing up for. So, I broke down what used to a long, two screen onboarding process into three screens that end above the fold.`}</p>
    <p>{`The concept of "the shorter the better" did not only apply to the onboarding flow, but throughout the application as well. A notable change for our application was taking our really long pages and separating it into several short pages. This helps with the mindset of one goal per page. For example, previously, we asked for users to select each of the products they wanted renew and to enter those product ids as well. Not only did this help with orienting the user to address one goal at a time, but it also make error states a lot cleaner and easier to implement on the backend.`}<br /><br /></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2fc296be418a272f89e5bd6920d8afe3/5e485/landing.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.55555555555556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAACwElEQVQ4y52RTW/cVBSG/VNZtRvYsSJigdghaFDFLlIZiqJKpVHaRGpFVNGCJpORmiZNpnPt8feMZ2xff13PV5IBRQ+yhwyTrgqWjnXO9fFz3nteTc91jMLg3egdh71DjvvHdGSH6vzm202uZ8u6bbdpO226abc+F5lY9WpV4cwc3vhv2Ppli5+eNmibRwwu+7eg1sTCntpYY4v95h4/7z3kZHCCP/foz32cqVP3au7c5dHzR2x8vcH2/ja7h8+488WnfHn/K+yJXcM6cYfNrc265+D4gG8am9zd+IzHr5/w/fYPfPL5HbaePcC/8NF64x5vxVuavx/S8wxenL/mu50GP77awSpN9ELHyA2arSat5hFG3KPx2y73dh9yIP7gcesF3+402Dt9iTOx0EQuCMuQaTlFXSmCYsCfsyv+ul7QzbqIVGAqE9MSdEQHI7YopyXVk16mJErC4ppyoXifvUerFHjSI09yiosCL/PI0px0nHEuzzkLzxCJwAkj/FGBVw6Q+ZDrq0ui2YiB7CPjlNEkohKniULgp32KrKRcjHFTl3AYkqgEQxlLQ5TJSyHZOykQic+RK/m1M6EjA5w4oB+O6asK2K2AOkHmc6EKJouiBiZxgpqpen9Lpw0CGTMvFX7h0hnEuMMxbubTciOen5WcDAN6SqBVr+NBwP6p4siPGeQecSjJxtnKkCr0xESXVp3b0meYjLBzG09GzNWEUAXLKxtK0A0D7GCCm0R4mVvvM5/kK2CtdC2MG+VVLm16kYeZWXVdm2ImDoEc4udefeVRENamrCtcD309r4eIVa9Wf/xnYjcXDIo+KstRs+JfYHEbsIJ/MLBWuITdHAi8rE+SjpFlfhv4kaGt9pQbtUuno6B2reUk9NRymP4foNqqqH8SGJFPFJXEmUTk69f7H8BKbS8zMaWLldof7OvjgH8DF211DdtaiW8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "How the landing page evolved after testing",
            "title": "How the landing page evolved after testing",
            "src": "/static/2fc296be418a272f89e5bd6920d8afe3/1cfc2/landing.png",
            "srcSet": ["/static/2fc296be418a272f89e5bd6920d8afe3/3684f/landing.png 225w", "/static/2fc296be418a272f89e5bd6920d8afe3/fc2a6/landing.png 450w", "/static/2fc296be418a272f89e5bd6920d8afe3/1cfc2/landing.png 900w", "/static/2fc296be418a272f89e5bd6920d8afe3/21482/landing.png 1350w", "/static/2fc296be418a272f89e5bd6920d8afe3/d61c2/landing.png 1800w", "/static/2fc296be418a272f89e5bd6920d8afe3/5e485/landing.png 2168w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  We revised the onboarding screens to caputre Ontarians' attention and deliver
  important information.
    </p>
    <p>{`To help Ontarians further understand the process, we looked at revamping the copy and microcopy to be more instructional and personal. We worked with a content designer to include more directional words that instruct the user how to complete the transaction. This also involved using pictures to aid people in completing the renewal easily.`}</p>
    <p>{`At first, we thought hiding the pictures in dropdowns would be accessible if needed and help to keep the page fairly short. However, when looking for their card numbers, we noticed users missing these dropdowns even though they were clearly labelled. While it may seem bizarre to us, this is a prime example of demonstrating that we, as creators, have too much context and are unable to initially see problems from the user's perspective. Thus, we embedded the image with instructions for the more confusing card number directly on the form below the input field.`}</p>
    <p>{`With more testing, we realized this still was not enough, and removed the dropdowns for all the images, making the page longer but more directly accessible for users to understand. In the final sprint, we found showing the images first even before the input field helped provide even more context and priming for users. This progression is seen in the image below.`}<br /><br /></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/899f2f3df0c25719c4f276feb1f4da72/c4cb0/pictures.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACnklEQVQ4y5WSTXPbVBSG/fPYsGbBD2BgzY41XbGDBrYwpYQC07hTSCGTTqetS+uJHcu2dHVlS4n14ciRrj7tJMZNH0bXJcPAsGBxRqPn3nnPe99zWnZmM4pHDKIBo3iIlVr8FzPmBr2gx2BuaNZUP+zTD48xzgx9pzWpJjzoPGDn7m2+f/wj3dMjvKVL+3mbL7/bYfdwy9za5dGrfb5tf8MvnYdafLwYc+/RLj/s73Jw9BuTckJLFpL2QZvbX33O1z+1+d0d4q2m3P91T7M7ew954Q7xr064c3iPdz96nw9vfYyZWrrJJzuf8s4H7/HZ7hfMLk9piUyQVillXvJ6/QdB5TNKR8QqJlkkcP1GMyuz6PlDDo5f8sLpI5SNUIJX7oCn5pE+k7m9FUyqFKUKNptr/MrHWBiklSJJMtbrzQ2Lyoir+orV1RKRCuxcYNgSW/jE5QJTmbQsZaGWiqqsuN5sCOuAsRqTlCmpagTXzMoZTmXTMXvs7XeYhj4ys2nyf3Z8zJNOj1MVIDKrcWhxVubM00uK1RtmpY/ITaZRxNMjC1WUBHWgnywiiSmmLPIEmUsaM1mdsL64JK7jrUOZW5hRwhNzSXe6ws1n2PmYob/g525M16nx8pl2HRQBF8sLilWhV8TOBNKfcRomhOVcN2g1wXrlhPnKI1y6yEzqsB3lMslmOOoEW0lkITBDh/5oSpBEOLnUgqY3xXY8/MynmUer6WQpwTi19Co0Yg1rDps47GzLZC4Q8QnPjYCRP8cpmsYWXpLghDWTJHqb4VuBv5dmyr6pZkWaaEaxz2Orpuud66E0Dl+6MYfjgkEY6Dutf4r9q/4SbfJaeCTnKWl+tt3DTODFIYXKiDJ/m+H/EdTfc4lI5M1Q7FRuWbr9/xNoE52GVYLLkQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Refined content and added pictures helped in aiding people to complete the transaction",
            "title": "Refined content and added pictures helped in aiding people to complete the transaction",
            "src": "/static/899f2f3df0c25719c4f276feb1f4da72/1cfc2/pictures.png",
            "srcSet": ["/static/899f2f3df0c25719c4f276feb1f4da72/3684f/pictures.png 225w", "/static/899f2f3df0c25719c4f276feb1f4da72/fc2a6/pictures.png 450w", "/static/899f2f3df0c25719c4f276feb1f4da72/1cfc2/pictures.png 900w", "/static/899f2f3df0c25719c4f276feb1f4da72/21482/pictures.png 1350w", "/static/899f2f3df0c25719c4f276feb1f4da72/d61c2/pictures.png 1800w", "/static/899f2f3df0c25719c4f276feb1f4da72/c4cb0/pictures.png 2638w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p className="caption">
  We refined the content and included visuals to aid Ontarians.
    </p>
    <p>{`In particular, the visuals were immensely helpful in making our platform easy to use and navigate the instructions. As users said:`}</p>
    <Blockquote text="“Pictures are worth a thousand words”" mdxType="Blockquote" />
    <p>{`We also thought more about errors and the different barriers Ontarians may
encounter when trying to renew their products. This includes both human
errors, as well as technical errors that Ontarians may encounter throughout
the process. Refining these states required work in both content and
information flow, and would need more thought before shipping the final
product.`}</p>
    <div id="solution"></div>
    <h4>{`Solution`}</h4>
    <h2>{`Final designs`}</h2>
    <iframe style={{
      "border": "1px solid rgba(0, 0, 0, 0.1)"
    }} width="100%" height="600" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FQjWeJZjwAGUw9MefKOHhsA%2FSO-Renewals-Website-Copy%3Fnode-id%3D718%253A29111%26viewport%3D519%252C114%252C0.032359443604946136%26scaling%3Dcontain" allowFullScreen></iframe>
    <p className="caption">
  The final designs were created in Figma.{" "}
  <a href="https://sorenewals.firebaseapp.com" target="_blank" rel="noreferrer">
    See the coded prototype.
  </a>
    </p>
    <div className="row">
  <div className="column-6 margin-top">
    <h3>Consolidated the many renewal platforms into a single service</h3>
    <p>
      We combined the major ServiceOntario products that need renewals into one
      platform, making it easy to renew everything at once. This makes the
      single service easier to find rather than Ontarians having to find the
      specific service to renew their single or combination of products. This is
      an proof of concept, and ideally other products that also need renewals
      will be included in the final version.
    </p>
  </div>
  <div className="column-3 column-centre-img column-img">
    <img src={oneWindow} alt="Created a consolidated service for numerous products" className="no-border-img half-img cursor" />
  </div>
    </div>
    <div className="row">
  <div className="column-3 column-centre-img column-img">
    <img src={simpleQuestions} alt="Simplified content and removed redundant data entry" className="no-border-img half-img cursor" />
  </div>
  <div className="column-6 margin-top">
    <h3>Simplified content and removed redundant questions</h3>
    <p>
      Rather than asking convoluted questions like "Have you moved or changed
      your address in the last 90 days?", we have used simpler questions that
      are easy to understand like "Do you currently live at the address on your
      driver’s licence?". We also now only ask users for their information once
      rather than requiring them to enter it multiple times.
    </p>
  </div>
    </div>
    <div className="row">
  <div className="column margin-top">
    <h3>Clarified errors and indicated next steps</h3>
    <p>
      Originally, not only were the errors confusing, but there were no
      directions for Ontarians on what to do after encountering an error. Now,
      we show errors inline for validation checks, and clearly outline why a
      user may be unable to continue the process online and actionable steps to
      finish their transaction in-person.
    </p>
  </div>
  <div className="column column-img2 col-img-right">
    <div className="row ">
      <div className="column ">
        <img src={error2} alt="Made errors very understandable with clear next steps" className="no-border-img slightly-half-img cursor" />
      </div>
      <div className="column">
        <img src={error1} alt="Explained problems to the user and gave next steps to go in-person" className="no-border-img slightly-half-img cursor" />
      </div>
    </div>
  </div>
    </div>
    <h2>{`Results`}</h2>
    <p>{`This work was a proof of concept done to help address one of Ontario's top ten online transactions. We pitched and presented our designs and findings to both executives and Ministry partners, receiving praise for the usability and improvements compared to the existing platform.`}</p>
    <p>{`When testing our prototype in the last week, we found that the prototype addressed all of our KPIs and exceeded our expectations. The final metrics include:`}</p>
    <div className="list">
  <div className="kpi">
    <img src={kpi} alt="kpi" />
    <h3>Reduced time spent on task by approximately 42%</h3>
  </div>
  <div className="kpi">
    <img src={kpi} alt="kpi" />
    <h3>
      90% of Ontarians would try the online renewal service before going
      in-person
    </h3>
  </div>
  <div className="kpi">
    <img src={kpi} alt="kpi" />
    <h3>Exceedingly positive feedback from Ontarians on the user experience</h3>
  </div>
    </div>
    <p>{`Overall, I believe our redesign was successful and creates a better user experience for Ontarians when they are renewing their government identification cards.`}</p>
    <h2>{`Next steps`}</h2>
    <p>{`One of the most time-consuming steps of the renewal process is typing the card number into the field. Although our redesign was able to mitigate confusion as to where the numbers are located on the card, the manual process of typing each number and letter is not ideal.`}</p>
    <p>{`A possible short term solution is to implement a photo scanning feature that would allow the user to point their camera at the card and automatically pick up the characters. However, this does not work well with mediums such as computers which may not have a camera. Instead, a more sustainable option is to implement the concept of `}<a href="https://news.ontario.ca/en/backgrounder/58873/ontario-onwards-digital-identity-project" target="_blank" rel="noreferrer" className="link">{`digital identity`}</a>{`
which could allow renewals with the click of a single button.`}</p>
    <p>{`Regarding the pitched redesigns, as of November 2020, work has been done to revise some of the content to use simpler language on the existing platform. While it is a good step in the right direction, unfortunately, there is no guarantee that other parts of our proof of concept redesign will be implemented in the near future.`}</p>
    <h2>{`Reflecting back`}</h2>
    <div className="row">
  <div className="column col-single-img column-img">
    <img src={Emily} alt="Emily user testing" />
    <p className="caption">
      This outside of the ServiceOntario where we conducted usability testing!
    </p>
  </div>
  <div className="column">
    <p>
      I completed a lot in such a short period of time, especially for a project
      that impacts many, many people. It was my first time completing a project
      at this scale and impact – it was the first project of my first co-op term
      as well!
    </p>
    <p>
      Having never done guerilla user testing before, I was extremely nervous
      the first time I was told to approach a stranger and ask for feedback.
      However, soon, I realized that these conversations helped me see the
      tangible fruits of how I am striving towards my goal of using technology
      to help people. I used this as a chance to overcome my anxieties when
      approaching and conducting user interviews with others.
    </p>
    <p>
      Overall, I am proud of the work we have done and I hope to continue
      working on impactful projects in the future.
    </p>
  </div>
    </div>
    <br />
    <h4>{`More case studies`}</h4>
    <MoreProjects projects={props.data.allMdx.edges} current={props.pageContext.frontmatter.order} mdxType="MoreProjects" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      